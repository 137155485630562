<template>
  <div>
    <v-row
      v-if="userRoleVerify"
    >
      <v-row>
        <v-col
          cols="3"
          class="ml-4 mb-0"
        >
          <v-autocomplete
            v-model="itemSearch"
            :items="listRegionsItems"
            dense
            hide-details
            label="Origem"
            sm="4"
            lg="3"
            class="pb-1"
            @input="onInput"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-col
        cols="12"
      >
        <progress-target
          :key="itemsSales"
          :sales="itemsSales[0]"
          :target-progress="targetGeneral"
        ></progress-target>
      </v-col>

      <v-row>
        <v-col>
          <retention-card-dashboard
            :key="itemsRetention"
            :retention="itemsRetention"
          />
        </v-col>
        <v-col
          cols="24"
          md="6"
        >
          <cancellation-card-dashboard
            :key="itemsCancellation"
            :cancellation="itemsCancellation"
          />
        </v-col>
      </v-row>

      <v-col
        cols="24"
        md="6"
      >
        <v-card
          v-show="showListVendorTopFive"
          outlined
        >
          <v-card-title>
            Progresso anual 🚀
            <v-spacer></v-spacer>
          </v-card-title>
          <apex-chart-balance
            :key="itemsSales"
            :data="itemsSales"
          ></apex-chart-balance>
        </v-card>
      </v-col>
      <v-col
        cols="24"
        md="6"
      >
        <top-five-vendors
          v-show="showListVendorTopFive"
          :key="itemsListVendors"
          :vendors="itemsListVendors"
          :progress="vendorProgress"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
      >
        <progress-target-vendor></progress-target-vendor>
      </v-col>
      <v-col
        v-for="customersOption in customersOptions"
        :key="customersOption.statTitle"
        cols="12"
        xl="4"
        lg="4"
        md="12"
      >
        <apex-chart-statistic
          :infos="customersOption"
        />
      </v-col>
      <v-col v-if="loadBlock">
        <v-row>
          <v-col
            v-for="info in infos"
            :key="info.statTitle"
            cols="12"
            xl="4"
            lg="4"
            md="12"
          >
            <apex-charts-widgets
              :infos="info"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            md="6"
          >
            <apex-chart-expense-ratio />
          </v-col>
          <v-col
            md="6"
          >
            <chart-model-vendor />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="sales"
      width="70vw"
    >
      <sales-modal @close="sales = false" />
    </v-dialog>
    <v-dialog
      v-model="deliveries"
      width="70vw"
    >
      <deliveries-modal @close="deliveries = false" />
    </v-dialog>
    <v-dialog
      v-model="second_parcel"
      width="70vw"
    >
      <second-parcel-modal
        :value="referenceMonth"
        @close="second_parcel = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import ApexChartStatistic from '@/components/charts/ApexChartStatistic.vue'
import ApexChartsWidgets from '@/components/charts/ApexChartWidgets.vue'
import ProgressTargetVendor from '@/components/charts/ProgressTargetVendor.vue'
import ApexChartExpenseRatio from '@/views/pages/sigma/Charts/ApexChartExpenseRatio.vue'
import ChartModelVendor from '@/views/pages/sigma/Charts/ChartModelVendor.vue'
import CancellationCardDashboard from '@/views/pages/sigma/dashboardChartsManager/CancellationCardDashboard.vue'
import ProgressTarget from '@/views/pages/sigma/dashboardChartsManager/ProgressTarget.vue'
import RetentionCardDashboard from '@/views/pages/sigma/dashboardChartsManager/RetentionCardDashboard.vue'
import TopFiveVendors from '@/views/pages/sigma/dashboardChartsManager/TopFiveVendors.vue'

import {
  mdiAccountGroup,
  mdiCakeVariant,
  mdiCellphoneLock,
  mdiFileDocumentMultiple,
  mdiHandshake,
  mdiMotorbike,
} from '@mdi/js'

import axiosIns from '@/plugins/axios'
import ApexChartBalance from '@/views/pages/sigma/dashboardChartsManager/ApexChartBalance.vue'
import DeliveriesModal from './DeliveriesModal.vue'
import SalesModal from './SalesModal.vue'
import SecondParcelModal from './SecondParcelModal.vue'

export default {
  components: {
    ApexChartExpenseRatio,
    ApexChartsWidgets,
    SalesModal,
    DeliveriesModal,
    ApexChartStatistic,
    ChartModelVendor,
    ProgressTarget,
    TopFiveVendors,
    RetentionCardDashboard,
    CancellationCardDashboard,
    ProgressTargetVendor,
    ApexChartBalance,
    SecondParcelModal,
  },
  data() {
    return {
      referenceMonth: '',
      listRegionsItems: ['IMPERATRIZ', 'AÇAILÂNDIA', 'GRAJAÚ'],
      salesRadialChart: {
        statTitle: 'Total Sales',
        statistics: '135k',
        series: [78],
      },

      sales: false,
      deliveries: false,
      second_parcel: false,
      customersOptions: [
        {
          // avatar: require('@/assets/images/handshake two people_3195568.png'),
          avatar: mdiHandshake,
          avatarWidth: '60',
          color: '#ea802e',
          change: '',
          chipColor: '',
          chipText: '',
          statistics: '0',
          statTitle: 'Vendas',
          event: this.openSalesModal,
          backgroundIcon: 'v-avatar-light-bg error--text justify-center',
        },
        {
          // avatar: require('@/assets/images/motorbike (2).png'),
          avatar: mdiMotorbike,
          avatarWidth: '60',
          color: '#6dc5f2',
          change: '',
          chipColor: '',
          chipText: '',
          statistics: '0',
          statTitle: 'Entregas',
          event: this.openDeliveriesModal,
          backgroundIcon: 'v-avatar-light-bg info--text justify-center',
        },
        {
          // avatar: require('@/assets/images/happy-birthday.png'),
          avatar: mdiCakeVariant,
          avatarWidth: '60',
          color: '#f4e000',
          change: '',
          chipColor: '',
          chipText: '',
          statistics: '0',
          statTitle: 'Aniversário',
          router: { name: 'sigma-birthday-person' },
          backgroundIcon: 'v-avatar-light-bg warning--text justify-center',
        },
        {
          avatar: mdiFileDocumentMultiple,
          avatarWidth: '60',
          color: 'success',
          change: '',
          chipColor: '',
          chipText: '',
          statistics: '0/0',
          event: this.openModalSales,
          statTitle: '2ª Parcela',
          reference: '',
          adimplencia: '0',
          backgroundIcon: 'v-avatar-light-bg success--text justify-center',
        },
        {
          // avatar: require('@/assets/images/people.png'),
          avatar: mdiAccountGroup,
          avatarWidth: '60',
          color: 'error',
          change: '',
          chipColor: '',
          chipText: '',
          statistics: '0',
          statTitle: 'Cota Alvo',
          router: { name: 'sigma-closing-group' },
          backgroundIcon: 'v-avatar-light-bg error--text justify-center',
        },
        {
          // avatar: require('@/assets/images/lock.png'),
          avatar: mdiCellphoneLock,
          avatarWidth: '60',
          color: '#ea802e',
          change: '',
          chipColor: '',
          chipText: '',
          statistics: '0',
          statTitle: 'Termo LGPD',
          router: { name: 'sigma-lgpd-vendor' },
          backgroundIcon: 'v-avatar-light-bg error--text justify-center',
        },
      ],
      loadBlock: false,
      infos: [],
      userRoleVerify: true,
      itemsRetention: '',
      itemSearch: '',
      showListVendorTopFive: false,
      itemsCancellation: '',
      itemsListVendors: '',
      vendorProgress: {
        vendor01: '',
        vendor02: '',
        vendor03: '',
        vendor04: '',
        vendor05: '',
      },
      itemsSales: '',
      targetGeneral: '',
    }
  },

  created() {
    this.getBirthdays()
    this.getSales()
    this.getOpenParcels()
    this.getCloset()
    this.getDelivery()
    this.holdBlock()
    this.getLgpdTerm()

    const userRole = localStorage.getItem('userRole')
    if (userRole === 'true') {
      this.userRoleVerify = true
    } else {
      this.userRoleVerify = false
    }
  },

  methods: {
    async onInput() {
      await this.getItemsRetention()
      await this.getItemsSalesYearly()
      await this.getTargetGeneralByRegion()
      await this.getItemsCancellation()
      await this.getListVendors()
    },
    async getItemsSalesYearly() {
      const body = {
        region: this.itemSearch,
      }
      await axiosIns.post('/api/v1/integration/contract/annual_sales_manager', body).then(res => {
        this.itemsSales = []
        this.itemsSales.push(res.data.data)
      })
    },
    async getTargetGeneralByRegion() {
      const body = {
        region: this.itemSearch,
      }
      await axiosIns.post('api/v1/integration/general_goal/filter_region', body).then(res => {
        this.targetGeneral = []
        this.targetGeneral.push(res.data.data)
      })
    },
    async getListVendors() {
      const body = {
        region: this.itemSearch,
      }
      await axiosIns.post('api/v1/integration/contract/top_five_sellers', body).then(res => {
        this.itemsListVendors = []
        this.itemsListVendors.push(res.data.data)
      })
    },
    async getItemsRetention() {
      const body = {
        region: this.itemSearch,
      }
      await axiosIns.post('api/v1/integration/contract/manager_retention', body).then(res => {
        this.itemsRetention = []
        this.itemsRetention.push(res.data.data)

        if (this.itemSearch === null) {
          this.showListVendorTopFive = false
        } else {
          this.showListVendorTopFive = true
        }
      })
    },

    async getItemsCancellation() {
      const body = {
        region: this.itemSearch,
      }

      await axiosIns.post('api/v1/integration/contract/manager_cancellation', body).then(res => {
        this.itemsCancellation = []
        this.itemsCancellation.push(res.data.data)

        if (this.itemSearch === null) {
          this.showListVendorTopFive = false
        } else {
          this.showListVendorTopFive = true
        }
      })
    },
    async openSalesModal() {
      this.sales = await true
    },
    async openDeliveriesModal() {
      this.deliveries = await true
    },
    async openModalSales() {
      this.second_parcel = true
    },
    async getSales() {
      await axiosIns.get('/api/v1/integration/contract/dash_sales').then(async res => {
        this.customersOptions[0].statistics = res.data.data[0].sales.toString()
          ? res.data.data[0].sales.toString()
          : '0'
      })
    },
    async getDelivery() {
      await axiosIns.get('/api/v1/integration/delivery/dash_deliveries').then(async res => {
        this.customersOptions[1].statistics = res.data[0].deliveries.toString()
          ? `R$ ${res.data[0].deliveries.toString()},00`
          : '0'
      })
    },
    async getBirthdays() {
      await axiosIns.get('/api/v1/integration/client/dash_birthdaysoftheday').then(async res => {
        this.customersOptions[2].statistics = res.data.data[0].birthdaysoftheday.toString()
          ? res.data.data[0].birthdaysoftheday.toString()
          : '0'
      })
    },
    async getOpenParcels() {
      await axiosIns.get('/api/v1/integration/contract/second_parcel_vendor').then(async res => {
        this.referenceMonth = res.data.data[0].reference
        this.customersOptions[3].statistics = `${res.data.data[0].parcela_02_paga ? res.data.data[0].parcela_02_paga : 0}/${
          res.data.data[0].total_venda ? res.data.data[0].total_venda : 0
        }`
        this.customersOptions[3].adimplencia = `${res.data.data[0].adimplencia ? res.data.data[0].adimplencia : ''}`
        this.customersOptions[3].reference = res.data.data[0].reference
      })
    },
    async getCloset() {
      await axiosIns.get('/api/v1/integration/contract/dash_group_closed').then(async res => {
        this.customersOptions[4].statistics = res.data.data[0].group_closed.toString()
      })
    },

    async getLgpdTerm() {
      await axiosIns.get('/api/v1/integration/lgpd_term/dash_lgpd')
        .then(res => {
          this.customersOptions[5].statistics = res.data.data[0].lgpd
        })
    },
    async holdBlock() {
      this.infos = []
      await axiosIns('api/v1/integration/contract/holding_block').then(async res => {
        const { data } = res.data
        this.infos[0] = {
          statTitle: data[0].reference ? data[0].reference : 'sem dados',
          contracts: data[0].sales ? data[0].sales : '0',
          color: 'error',
          adimplentes: data[0].parcel_2 ? data[0].parcel_2 : '0',
          comission: data[0].total ? data[0].total : '0',
          indice: data[0].percentage ? data[0].percentage : '0',
        }
        this.infos[1] = {
          statTitle: data[1].reference ? data[1].reference : 'sem dados',
          contracts: data[1].sales ? data[1].sales : '0',
          color: 'warning',
          adimplentes: data[1].parcel_2 ? data[1].parcel_2 : '0',
          comission: data[1].total ? data[1].total : '0',
          indice: data[1].percentage ? data[1].percentage : '0',
        }
        this.infos[2] = {
          statTitle: data[2].reference ? data[2].reference : 'sem dados',
          contracts: data[2].sales ? data[2].sales : '0',
          color: 'warning',
          adimplentes: data[2].parcel_2 ? data[2].parcel_2 : '0',
          comission: data[2].total ? data[2].total : '0',
          indice: data[2].percentage ? data[2].percentage : '0',
        }
        this.infos[3] = {
          statTitle: data[3].reference ? data[3].reference : 'sem dados',
          contracts: data[3].sales ? data[3].sales : '0',
          color: 'info',
          adimplentes: data[3].parcel_2 ? data[3].parcel_2 : '0',
          comission: data[3].total ? data[3].total : '0',
          indice: data[3].percentage ? data[3].percentage : '0',
        }
        this.infos[4] = {
          statTitle: data[4].reference ? data[4].reference : 'sem dados',
          contracts: data[4].sales ? data[4].sales : '0',
          color: 'info',
          adimplentes: data[4].parcel_2 ? data[4].parcel_2 : '0',
          comission: data[4].total ? data[4].total : '0',
          indice: data[4].percentage ? data[4].percentage : '0',
        }
        this.infos[5] = {
          statTitle: data[5].reference ? data[5].reference : 'sem dados',
          contracts: data[5].sales ? data[5].sales : '0',
          color: 'info',
          adimplentes: data[5].parcel_2 ? data[5].parcel_2 : '0',
          comission: data[5].total ? data[5].total : '0',
          indice: data[5].percentage ? data[5].percentage : '0',
        }
        this.infos[6] = {
          statTitle: data[6].reference ? data[6].reference : 'sem dados',
          contracts: data[6].sales ? data[6].sales : '0',
          color: 'info',
          adimplentes: data[6].parcel_2 ? data[6].parcel_2 : '0',
          comission: data[6].total ? data[6].total : '0',
          indice: data[6].percentage ? data[6].percentage : '0',
        }
      })
      this.loadBlock = await true
    },
  },
}
</script>

<style>
</style>
