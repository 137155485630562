import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Vendas "),_c(VSpacer),_c(VAlert,{attrs:{"text":"","color":"success"}},[_vm._v(" Média: "+_vm._s(_vm.average)+" ")])],1),_c(VCardSubtitle,[_vm._v("Suas vendas nos últimos 6 meses 🚀")]),_c(VCardText,[_c('vue-apex-charts',{attrs:{"type":"donut","height":"455","options":_vm.donutChart.chartOptions,"series":_vm.donutChart.series}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }