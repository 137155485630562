import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c(VCardTitle,[_vm._v("Relatório de Entregas")]),_c(VSpacer),_c(VCard,{staticClass:"d-flex align-self-center"},[_c(VIcon,{staticClass:"me-1 align-self-center",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCalendarBlankOutline)+" ")]),_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"dense":"","label":"Mês referência","hide-details":"","readonly":"","outlined":""},on:{"input":_vm.onInput},model:{value:(_vm.filterOptions.date),callback:function ($$v) {_vm.$set(_vm.filterOptions, "date", $$v)},expression:"filterOptions.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c(VDatePicker,{attrs:{"type":"month","width":"270","locale":"pt-BR"},on:{"input":_vm.onInput},model:{value:(_vm.filterOptions.date),callback:function ($$v) {_vm.$set(_vm.filterOptions, "date", $$v)},expression:"filterOptions.date"}})],1)],1)],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VSimpleTable,{attrs:{"fixed-header":"","height":"65vh"}},[_c('thead',[_c('tr',_vm._l((_vm.headers),function(header){return _c('th',{key:header.text},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)]),_c('tbody',[(_vm.loading)?_c(VCardText,{staticClass:"progress-circular"},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":40,"color":"gray"}})],1):_vm._e(),(_vm.sales.length === 0)?_c('span',{class:_vm.classNoDataText},[_vm._v(" "+_vm._s(_vm.noDataText)+" ")]):_vm._l((_vm.sales),function(item){return _c('tr',{key:item.reference},_vm._l((_vm.headers),function(i){return _c('td',{key:i.value},[_vm._v(" "+_vm._s(item[i.value] ? item[i.value] : _vm.noDataText)+" ")])}),0)})],2),_c('tfoot',{staticClass:"fixed-footer"},[_c('tr',[_c('th',[_vm._v(" TOTAL DE ENTREGAS: "+_vm._s(_vm.total.total_delivery)+" ")]),_c('th'),_c('th'),_c('th'),_c('th',[_vm._v(" "+_vm._s(Number(_vm.total.total_bonus).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))+" ")])])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }