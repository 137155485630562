<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-card
          outlined
        >
          <v-card-title class="align-start">
            <span>Top 5 melhores vendedores 🔥😍</span>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="vendors[0]"
            hide-default-footer
          >
            <template #[`item.name`]="{item}">
              <div class="d-flex align-center">
                <v-avatar
                  :color="resolveUserProgressVariant(item.progress)"
                  :class="`v-avatar-light-bg ${resolveUserProgressVariant(item.progress)}--text`"
                  size="30"
                >
                  <span
                    class="font-weight-medium"
                    :color="resolveUserProgressVariant(item.progress)"
                  >{{ avatarText(item.name) }}</span>
                </v-avatar>
                <div class="d-flex flex-column ms-3">
                  <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.name }}</span>
                </div>
              </div>
            </template>
            <!-- progress -->
            <template #[`item.progress`]="{item}">
              <span>{{ Number(item.progress) }}%</span>
              <v-progress-linear
                height="6"
                rounded
                class="project-progress mt-1"
                :color="resolveUserProgressVariant(item.progress)"
                :value="Number(item.progress)"
              ></v-progress-linear>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>

export default {
  components: {
  },
  props: {
    vendors: {
      type: Array,
      required: true,
    },
    progress: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'VENDEDOR', value: 'name', sortable: false,
        },
        {
          text: 'VENDAS', value: 'vendas', align: 'center', sortable: false,
        },
        {
          text: 'META', value: 'goal', align: 'center', sortable: false,
        },
        { text: 'PROGRESSO', value: 'progress', sortable: false },
      ],
      chartOptions: {
        chart: {
          sparkline: {
            enabled: false,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '30%',
            },
            track: {
              background: '#ebe9f1',
            },
            dataLabels: {
              show: false,
              name: {
                show: false,
              },
            },
          },
        },

        grid: {
          padding: {
            bottom: -15,
          },
        },
      },
    }
  },

  computed: {

  },

  methods: {
    avatarText(value) {
      if (!value) return ''
      const nameArray = value.split(' ')
      const first = nameArray[0]
      const last = nameArray[nameArray.length - 1]

      const newName = [first, last]

      return newName.map(word => word.charAt(0).toUpperCase()).join('')
    },
    resolveUserProgressVariant(progrss) {
      if (progrss <= 25) return 'error'
      if (progrss > 25 && progrss <= 50) return 'warning'
      if (progrss > 50 && progrss <= 75) return 'primary'
      if (progrss > 75 && progrss <= 100) return 'success'
      if (progrss > 100) return 'purple'

      return 'secondary'
    },
    getChartConfig(value) {
      const options = JSON.parse(JSON.stringify(this.chartOptions))
      options.colors = [value]

      return options
    },
  },
}
</script>
<style>
  .team-table tbody td {
    border-bottom: none !important
  }

</style>
