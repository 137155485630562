import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(" Relatório de retenção - "+_vm._s(_vm.value)),_c(VSpacer),_vm._v(" "),_c(VIcon,{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.reports,"disable-sort":""},scopedSlots:_vm._u([{key:"item.contract_status",fn:function(ref){
var item = ref.item;
return [_c(VAlert,{staticClass:"badge",attrs:{"text":"","color":item.contract_status.trim() === 'PRE-CONTEMPLADO' ? 'info' :
            item.contract_status.trim() === 'PRE-CANCELADO' ? 'purple' :
            item.contract_status.trim() === 'CANCELADO' ? 'error' :
            item.contract_status.trim() === 'CANCELADO NO GRUPO' ? 'error' :
            item.contract_status.trim() === 'NORMAL' ? 'success' :
            item.contract_status.trim() === 'CONTEMPLADO' ? 'success' :
            ''}},[_vm._v(" "+_vm._s(item.contract_status)+" ")])]}},{key:"item.parcel_2",fn:function(ref){
            var item = ref.item;
return [_c('span',{class:Number(item.parcel_2) <= 0 ? 'zeroed' : null},[_vm._v(" "+_vm._s(Number(item.parcel_2 ? item.parcel_2 : 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))+" ")])]}},{key:"item.parcel_5",fn:function(ref){
            var item = ref.item;
return [_c('span',{class:Number(item.parcel_5) <= 0 ? 'zeroed' : null},[_vm._v(" "+_vm._s(Number(item.parcel_5 ? item.parcel_5 : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))+" ")])]}},{key:"item.parcel_value",fn:function(ref){
            var item = ref.item;
return [_c('span',{class:Number(item.parcel_value) <= 0 ? 'zeroed' : null},[_vm._v(" "+_vm._s(Number(item.parcel_value ? item.parcel_value : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))+" ")])]}},{key:"item.total",fn:function(ref){
            var item = ref.item;
return [_c('span',{class:Number(item.total) <= 0 ? 'zeroed' : null},[_vm._v(" "+_vm._s(Number(item.total ? item.total : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))+" ")])]}},{key:"foot",fn:function(ref){return [_c('tfoot',{staticClass:"footer"},[_c('th',{staticClass:"contracts"},[_c('h3',[_vm._v(" "+_vm._s(_vm.footer.contracts)+" ")])]),_c('th',[_vm._v("-")]),_c('th',[_vm._v("-")]),_c('th',[_vm._v("-")]),_c('th',[_vm._v("-")]),_c('th',[_vm._v("-")]),_c('th',{},[_c('h4',[_vm._v(" "+_vm._s(_vm.footer.calculo)+" ")])]),_c('th',[_c('h4',[_vm._v(" "+_vm._s(Number(_vm.footer.parcel_2).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))+" ")])]),_c('th',{},[_c('h4',[_vm._v(" "+_vm._s(Number(_vm.footer.parcel_5).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))+" ")])]),_c('th',{},[_c('h4',[_vm._v(" "+_vm._s(Number(_vm.footer.total).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))+" ")])])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }