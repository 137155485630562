<template>
  <v-card>
    <v-card class="d-flex">
      <v-card>
        <v-card-title>
          Modelos
        </v-card-title>
        <v-card-subtitle>Os modelos mais vendidos no mês 🔥</v-card-subtitle>
      </v-card>
      <v-spacer></v-spacer>
      <v-card class="d-flex align-self-center mr-6">
        <v-icon
          size="18"
          class="me-1 align-self-center"
        >
          {{ icons.mdiCalendarBlankOutline }}
        </v-icon>
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filterOptions.date"
              dense
              label="Mês referência"
              hide-details
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              @input="onInput"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterOptions.date"
            type="month"
            width="270"
            locale="pt-BR"
            @input="onInput"
          ></v-date-picker>
        </v-menu>
      </v-card>
    </v-card>

    <!-- chart -->
    <v-card-text class="mt-5">
      <chartjs-component-bar-chart
        :key="latestBarChart.data.labels"
        :height="440"
        :data="latestBarChart.data"
        :options="latestBarChart.options"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import { mdiCalendarBlankOutline } from '@mdi/js'
import ChartjsComponentBarChart from './ChartjsComponentBarChart.vue'

export default {
  components: {
    ChartjsComponentBarChart,
  },
  data() {
    return {
      icons: {
        mdiCalendarBlankOutline,
      },
      dateMenu: false,
      filterOptions: {
        date: '',
      },

      // Dados do gráfico de vendas
      chartColors: {},

      latestBarChart: {},
    }
  },

  created() {
    this.chartColors = {
      primaryColorShade: '#836AF9',
      yellowColor: '#ffe800',
      successColorShade: '#28dac6',
      warningColorShade: '#ffe802',
      warningLightColor: '#FDAC34',
      infoColorShade: '#299AFF',
      greyColor: '#4F5D70',
      blueColor: '#2c9aff',
      blueLightColor: '#84D0FF',
      greyLightColor: '#EDF1F4',
      tooltipShadow: 'rgba(0, 0, 0, 0.25)',
      lineChartPrimary: '#666ee8',
      lineChartDanger: '#ff4961',
      labelColor: '#6e6b7b',
      grid_line_color: 'rgba(200, 200, 200, 0.2)',

    }
    this.latestBarChart = {
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: '#ffcf5c',
            borderColor: 'transparent',
            barThickness: 15,
          },
        ],
      },
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: this.chartColors.tooltipShadow,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: this.chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
              ticks: {
                stepSize: 100,
                min: 0,
                max: 400,
                fontColor: this.chartColors.labelColor,
              },
            },
          ],
        },
      },

    }
  },

  methods: {
    async onInput() {
      await this.getDataChart()
      this.dateMenu = false
    },

    async getDataChart() {
      const labelsData = []
      const totalData = []
      const body = {
        date_bordero: this.filterOptions.date,
      }
      await axiosIns.post('/api/v1/integration/contract/best_selling_models', body)
        .then(res => {
          res.data.data.map(item => {
            labelsData.push(item.vehicle_model)
            totalData.push(item.total)
            this.latestBarChart = {
              data: {
                labels: labelsData,
                datasets: [
                  {
                    data: totalData,
                    backgroundColor: '#ffcf5c',
                    borderColor: 'transparent',
                    barThickness: 30,
                  },
                ],
              },
              options: {
                elements: {
                  rectangle: {
                    borderWidth: 2,
                    borderSkipped: 'bottom',
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
                responsiveAnimationDuration: 500,
                legend: {
                  display: false,
                },
                tooltips: {
                  // Updated default tooltip UI
                  shadowOffsetX: 1,
                  shadowOffsetY: 1,
                  shadowBlur: 8,
                  shadowColor: this.chartColors.tooltipShadow,
                },
                scales: {
                  xAxes: [
                    {
                      display: true,
                      gridLines: {
                        display: true,
                        color: this.chartColors.grid_line_color,
                        zeroLineColor: this.chartColors.grid_line_color,
                      },
                      scaleLabel: {
                        display: false,
                      },
                      ticks: {
                        fontColor: this.chartColors.labelColor,
                      },
                    },
                  ],
                  yAxes: [
                    {
                      display: true,
                      gridLines: {
                        color: this.chartColors.grid_line_color,
                        zeroLineColor: this.chartColors.grid_line_color,
                      },
                      ticks: {
                        stepSize: 5,
                        min: 0,
                        max: 15,
                        fontColor: this.chartColors.labelColor,
                      },
                    },
                  ],
                },
              },

            }
          })
        })
    },
  },
}
</script>
