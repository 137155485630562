<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between">
      <v-card-title>
        Safra de vendas <span class="title">Total: {{ sales.length }}</span>
      </v-card-title>

      <v-icon
        @click="$emit('close')"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-simple-table
            fixed-header
            height="65vh"
          >
            <thead>
              <tr>
                <th
                  v-for="header in headers"
                  :key="header.text"
                >
                  {{ header.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in sales"
                :key="item.reference"
              >
                <td
                  v-for="i in headers"
                  :key="i.value"
                >
                  {{ item[i.value] }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { mdiClose } from '@mdi/js'

export default {
  data() {
    return {
      headers: [
        { text: 'CONTRATO', value: 'contract_number' },
        { text: 'CLIENTE', value: 'client_name' },
        { text: 'MODELO', value: 'vehicle_model' },
        { text: 'DATA VENDA', value: 'date_bordero' },
        { text: 'TELEFONE', value: 'phone' },
      ],
      sales: [],
      icons: {
        mdiClose,
      },
    }
  },
  async created() {
    await this.getSales()
  },
  methods: {
    async getSales() {
      await axiosIns.get('/api/v1/integration/contract/detailed_sales').then(async res => {
        // this.sales = await res.data.data
        res.data.data.map(dt => {
          this.sales.push({
            contract_number: `${dt.proposal_number ? dt.proposal_number : '0000'}-${
              dt.proposal_digit ? dt.proposal_digit : '0'
            }`,
            client_name: dt.client_name ? dt.client_name : '',
            vehicle_model: dt.vehicle_model ? dt.vehicle_model : '',
            date_bordero: dt.date_bordero ? dt.date_bordero : '',
            phone: dt.phone ? dt.phone : '',
          })
        })
      })
    },
  },
}
</script>
<style scoped>
  .title {
    margin-left: 24px;
  }
</style>
