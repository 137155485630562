<template>
  <v-card>
    <v-card-title>
      Relatório de retenção - {{ value }}<v-spacer /> <v-icon @click="$emit('close')">
        {{ icons.mdiClose }}
      </v-icon>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="reports"
        disable-sort
      >
        <template
          #[`item.contract_status`]="{item}"
        >
          <v-alert
            text
            class="badge"
            :color="
              item.contract_status.trim() === 'PRE-CONTEMPLADO' ? 'info' :
              item.contract_status.trim() === 'PRE-CANCELADO' ? 'purple' :
              item.contract_status.trim() === 'CANCELADO' ? 'error' :
              item.contract_status.trim() === 'CANCELADO NO GRUPO' ? 'error' :
              item.contract_status.trim() === 'NORMAL' ? 'success' :
              item.contract_status.trim() === 'CONTEMPLADO' ? 'success' :
              ''
            "
          >
            {{ item.contract_status }}
          </v-alert>
        </template>
        <template #[`item.parcel_2`]="{item}">
          <span :class="Number(item.parcel_2) <= 0 ? 'zeroed' : null">
            {{ item.parcel_2 }}
          </span>
        </template>
        <template #[`item.parcel_4`]="{item}">
          <span :class="Number(item.parcel_4) <= 0 ? 'zeroed' : null">
            {{ item.parcel_4 }}
          </span>
        </template>
        <template v-slot:foot="{items}">
          <tfoot class="footer">
            <th class="contracts">
              <h3>
                {{ footer.contracts }}
              </h3>
            </th>
            <th>-</th>
            <th>-</th>
            <th>-</th>
            <th>-</th>
            <th>-</th>
            <th class="">
              <h4>
                {{ footer.calculo }}
              </h4>
            </th>
            <th>
              <h4>
                {{ Number(footer.parcel_2).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
              </h4>
            </th>

            <th class="">
              <h4>
                {{ Number(footer.total).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
              </h4>
            </th>
          </tfoot>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import myMixin from '@/plugins/mixin'
import { mdiClose } from '@mdi/js'

export default {
  mixins: [myMixin],
  props: {
    value: { type: String, default: '' },
  },
  setup() {
    return { icons: { mdiClose } }
  },
  data() {
    return {
      headers: [
        { text: 'CLIENTE', value: 'client_name' },
        { text: 'TELEFONE', value: 'phone' },
        { text: 'GRUPO COTA R D', value: 'group' },
        { text: 'VENCTO', value: 'due_date_next' },
        { text: 'STATUS', value: 'contract_status', align: 'center' },
        { text: 'Nº PARCELA', value: 'parcel', align: 'center' },
        { text: 'R$ PARCELA', value: 'parcel_value', align: 'center' },
        { text: '2ª PARC', value: 'parcel_2', align: 'center' },
        { text: 'TOTAL', value: 'total', align: 'center' },
      ],
      reports: [],
      footer: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      const body = {
        reference: this.value,

        // .toLowerCase().replace(/\b[a-z]/g, letter => letter.toUpperCase()),
      }
      axiosIns.post('/api/v1/integration/contract/detailed_retention_report', body).then(res => {
        const reports = res.data.data.tableBody
        this.reports = []

        reports.map(dt => {
          this.reports.push({
            client_name: dt.client_name ? dt.client_name : '',
            phone: dt.phone ? dt.phone : '',
            group: dt.group ? dt.group : '',
            due_date_next: dt.due_date_next ? this.formatDate(dt.due_date_next) : '',
            contract_status: dt.contract_status ? dt.contract_status : '',
            parcel: dt.parcel ? dt.parcel : '',
            parcel_value: dt.parcel_value ? dt.parcel_value : '',
            parcel_2: dt.parcel_2 ? dt.parcel_2 : '',
            parcel_4: dt.parcel_4 ? dt.parcel_4 : '',
            total: dt.total ? dt.total : '',
          })
        })

        // eslint-disable-next-line prefer-destructuring
        this.footer = res.data.data.tableFooter[0]

        // this.footer = await Object.keys(footer).map(async dt => footer[dt])

        // this.footer = await res.data.data.tableFooter[0]
      })
    },
  },
}
</script>
<style>
.fixed-footer {
  position: sticky;
  bottom: 0;
}
.footer{
  height: 50px;
}
.badge{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 40px;
}
.contracts{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  height: 50px;
  width: 100%;
}

.zeroed{
  color: #C34551;
  font-weight: 700;
}
</style>
