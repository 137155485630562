<template>
  <v-card>
    <v-card-title>
      <v-card-title>Relatório de Entregas</v-card-title>
      <v-spacer />
      <v-card class="d-flex align-self-center">
        <v-icon
          size="18"
          class="me-1 align-self-center"
        >
          {{ icons.mdiCalendarBlankOutline }}
        </v-icon>
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filterOptions.date"
              dense
              label="Mês referência"
              hide-details
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              @input="onInput"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterOptions.date"
            type="month"
            width="270"
            locale="pt-BR"
            @input="onInput"
          ></v-date-picker>
        </v-menu>
      </v-card>

      <!-- <v-spacer /> -->
      <!-- <v-icon
        ml-5
        @click="$emit('close')"
      >
        {{ icons.mdiClose }}
      </v-icon> -->
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-simple-table
            fixed-header
            height="65vh"
          >
            <thead>
              <tr>
                <th
                  v-for="header in headers"
                  :key="header.text"
                >
                  {{ header.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <v-card-text
                v-if="loading"
                class="progress-circular"
              >
                <v-progress-circular
                  indeterminate
                  :size="40"
                  color="gray"
                />
              </v-card-text>
              <span
                v-if="sales.length === 0"
                :class="classNoDataText"
              >
                {{ noDataText }}
              </span>
              <tr
                v-for="item in sales"
                v-else
                :key="item.reference"
              >
                <td
                  v-for="i in headers"
                  :key="i.value"
                >
                  {{ item[i.value] ? item[i.value] : noDataText }}
                </td>
              </tr>
            </tbody>
            <tfoot class="fixed-footer">
              <tr>
                <th>
                  TOTAL DE ENTREGAS: {{ total.total_delivery }}
                </th>
                <th>
                </th>
                <th>
                </th>
                <th>
                </th>
                <th>
                  {{ Number(total.total_bonus).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
                </th>
              </tr>
            </tfoot>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { mdiCalendarBlankOutline, mdiClose } from '@mdi/js'

export default {
  data() {
    return {
      loading: false,
      classNoDataText: '',
      noDataText: '',
      dateMenu: false,
      filterOptions: {
        date: '',
      },
      headers: [
        { text: 'CONTRATO', value: 'contract_number' },
        { text: 'CLIENTE', value: 'client_name' },
        { text: 'MODELO', value: 'vehicle_model' },
        { text: 'NOTA FISCAL', value: 'invoice_number' },
        { text: 'BONIFICAÇÃO', value: 'bonus_value' },
      ],
      sales: [],
      total: {
        total_delivery: 0,
        total_bonus: 0,
      },
      icons: {
        mdiClose,
        mdiCalendarBlankOutline,
      },
    }
  },
  async created() {
    this.noDataText = 'SELECIONE UM FILTRO PARA VIZUALIZAR OS DADOS'
    this.classNoDataText = 'colorWarning'
    await this.getSales()
  },
  methods: {
    async onInput() {
      this.getDataDelivery()
      this.dateMenu = false
    },

    getDataDelivery() {
      this.loading = true
      this.noDataText = ''
      const body = {
        invoice_date: this.filterOptions.date,
      }

      this.sales = []

      axiosIns.post('/api/v1/integration/delivery/filter_vendor', body)
        .then(res => {
          if (res.data.data[0][0].length > 0) {
            res.data.data[0][0].map(item => {
              this.sales.push({
                contract_number: item.contract_number ? item.contract_number : '',
                client_name: item.client_name ? item.client_name : '',
                vehicle_model: item.vehicle_model ? item.vehicle_model : '',
                invoice_number: item.invoice_number ? item.invoice_number : '',
                bonus_value: item.bonus_value ? item.bonus_value : '',
              })
            })
            this.total = {
              total_delivery: res.data.data[0].total_delivery,
              total_bonus: res.data.data[0].total_bonus,
            }

            this.loading = false
          } else {
            this.noDataText = 'NENHUM REGISTRO ENCONTRADO'
            this.classNoDataText = 'colorEmpty'
            this.sales = []

            this.total = {
              total_delivery: res.data.data[0].total_delivery,
              total_bonus: res.data.data[0].total_bonus,
            }

            this.loading = false
          }
        })
    },
    async getSales() {
      // this.loading = true
      await axiosIns.get('/api/v1/integration/delivery/delivery_detail').then(res => {
        res.data[0][0].map(dt => {
          this.sales.push({
            contract_number: dt.contract_number ? dt.contract_number : '',
            client_name: dt.client_name ? dt.client_name : '',
            vehicle_model: dt.vehicle_model ? dt.vehicle_model : '',
            invoice_number: dt.invoice_number ? dt.invoice_number : '',
            bonus_value: dt.bonus_value ? dt.bonus_value : '',
          })
        })
        this.total = {
          total_delivery: res.data[0].total_delivery,
          total_bonus: res.data[0].total_bonus,
        }
      })

      // this.loading = false
    },
  },
}
</script>
<style>
.colorWarning {
  color: #ff9800 !important;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorEmpty {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.progress-circular {
  width: 200%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
