<template>
  <div>
    <vue-apex-charts
      type="line"
      height="290"
      :options="lineChartSimple.chartOptions"
      :series="lineChartSimple.series"
    />
  </div>
</template>

<script>
import themeConfig from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: { VueApexCharts },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lineChartSimple: {},
      valuesSales: [],
    }
  },

  created() {
    this.data[0].map(item => {
      this.valuesSales.push(item.sales)
    })
    const $themeColors = themeConfig.themes.light
    this.lineChartSimple = {
      series: [
        {
          data: this.valuesSales,
        },
      ],
      chartOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 6,
          strokeOpacity: 2,
          strokeColors: [$themeColors.secondary],
          colors: [$themeColors.error],
        },
        colors: [$themeColors.warning],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          custom(data) {
            return `${'<div class="px-2 py-30"><span>'}${
              data.series[data.seriesIndex][data.dataPointIndex]
            } cotas</span></div>`
          },
        },
        xaxis: {
          categories: [
            'JAN',
            'FEV',
            'MAR',
            'ABR',
            'MAI',
            'JUN',
            'JUL',
            'AGO',
            'SET',
            'OUT',
            'NOV',
            'DEZ',
          ],
        },
        yaxis: {
        // opposite: isRtl,
        },
      },
    }

    // const body = {
    //   region: 'IMPERATRIZ',
    // }
    // axiosIns.post('/api/v1/integration/contract/annual_sales_manager', body)
    //   .then(res => {
    //   })
  },
}
</script>
