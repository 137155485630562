import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCard,{staticClass:"d-flex"},[_c(VCard,[_c(VCardTitle,[_vm._v(" Modelos ")]),_c(VCardSubtitle,[_vm._v("Os modelos mais vendidos no mês 🔥")])],1),_c(VSpacer),_c(VCard,{staticClass:"d-flex align-self-center mr-6"},[_c(VIcon,{staticClass:"me-1 align-self-center",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCalendarBlankOutline)+" ")]),_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"dense":"","label":"Mês referência","hide-details":"","readonly":"","outlined":""},on:{"input":_vm.onInput},model:{value:(_vm.filterOptions.date),callback:function ($$v) {_vm.$set(_vm.filterOptions, "date", $$v)},expression:"filterOptions.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c(VDatePicker,{attrs:{"type":"month","width":"270","locale":"pt-BR"},on:{"input":_vm.onInput},model:{value:(_vm.filterOptions.date),callback:function ($$v) {_vm.$set(_vm.filterOptions, "date", $$v)},expression:"filterOptions.date"}})],1)],1)],1),_c(VCardText,{staticClass:"mt-5"},[_c('chartjs-component-bar-chart',{key:_vm.latestBarChart.data.labels,attrs:{"height":440,"data":_vm.latestBarChart.data,"options":_vm.latestBarChart.options}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }