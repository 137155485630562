<template>
  <div>
    <v-card class="pr-4">
      <v-card-title class="align-start pt-4 pr-8 flex-nowrap">
        <div>
          <p class="mb-0 font-weight-bold text-1xl">
            {{ infos.statTitle }}
          </p>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          class="me-n7 mt-n1"
          @click="modal = true"
        >
          <v-icon size="30">
            {{ icons.mdiDotsHorizontalCircle }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0 pt-5">
        <v-list
          two-line
          subheader
        >
          <v-list-item class="pa-0">
            <v-list-item-avatar
              class="v-avatar-light-bg success--text justify-center"
              size="40"
              rounded
            >
              <v-icon
                size="30"
                color="success"
              >
                {{ icons.mdiCurrencyUsd }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-semibold">
                {{ infos.comission ? Number(infos.comission).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : "R$ 0,00" }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ infos.indice }}%</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="pa-0">
            <v-list-item-avatar
              class="v-avatar-light-bg primary--text justify-center"
              size="40"
              rounded
            >
              <v-icon
                size="30"
                color="primary"
              >
                {{ icons.mdiTrendingUp }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-semibold">
                {{ infos.adimplentes }}
              </v-list-item-title>
              <v-list-item-subtitle>Adimplentes</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="pa-0">
            <v-list-item-avatar
              color="primary"
              class="v-avatar-light-bg secondary--text justify-center"
              size="40"
              rounded
            >
              <v-icon
                size="30"
                color="secondary"
              >
                {{ icons.mdiAccountMultipleCheck }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-semibold">
                {{ infos.contracts }}
              </v-list-item-title>
              <v-list-item-subtitle>Contratos</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="modal"
      width="80vw"
    >
      <report-retention-modal
        :value="infos.statTitle"
        @close="modal = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import ReportRetentionModal from '@/views/pages/sigma/vendor/ReportRetentionModal.vue'
import {
  mdiAccountMultipleCheck, mdiChartBar, mdiCurrencyUsd, mdiDotsHorizontalCircle, mdiDotsVertical, mdiTrendingUp,
} from '@mdi/js'

export default {
  components: {
    ReportRetentionModal,
  },
  props: {
    infos: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      modal: false,
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
        mdiChartBar,
        mdiAccountMultipleCheck,
        mdiDotsHorizontalCircle,
      },
    }
  },
}
</script>
