<template>
  <v-card>
    <v-col
      cols="12"
      sm="12"
      order="2"
      order-md="1"
      class="mt-5"
    >
      <v-card class="triangle-card">
        <v-row>
          <v-col cols="8">
            <v-card-title class="page-title text-no-wrap pt-2">
              Parabéns {{ user.name }}! 🥳
            </v-card-title>
            <v-card-subtitle class="text-no-wrap">
              Você já bateu a meta deste mês!
            </v-card-subtitle>
            <v-card-text class="d-flex align-center pb-2">
              <div>
                <p class="text-2xl font-weight-semibold primary--text mb-0">
                  {{ user.target }} cotas
                </p>
                <p>{{ Math.round(progress) }}% da meta 🤟🏻</p>
                <v-switch
                  v-model="switch1"
                  inset
                  color="purple"
                  :label="'Não mostrar novamente'"
                ></v-switch>
                <!-- <v-btn
                  small
                  :color="color"
                  @click="$emit('close')"
                >
                  Obrigado!
                </v-btn> -->
              </div>
            </v-card-text>
          </v-col>
          <v-col
            cols="4"
            class="triangle-bg align-self-end"
          >
            <v-img
              width="166px"
              height="190px"
              :src="require(`@/assets/images/misc/triangle-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
              class="triangle-bg d-flex align-self-end"
            >
              <v-img
                height="108"
                max-width="83"
                class="gamification-trophy mt-16 ml-11"
                src="@/assets/images/misc/trophy.png"
              ></v-img>
            </v-img>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-card>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      color: 'primary',
      switch1: false,
    }
  },
  watch: {
    switch1(n) {
      if (n) {
        localStorage.setItem('congratulation', 'false')
      } else {
        localStorage.setItem('congratulation', 'true')
      }
    },
  },
  methods: {

  },
}
</script>
