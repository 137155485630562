import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c(VCardTitle,[_vm._v(" Safra de vendas "),_c('span',{staticClass:"title"},[_vm._v("Total: "+_vm._s(_vm.sales.length))])]),_c(VIcon,{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VSimpleTable,{attrs:{"fixed-header":"","height":"65vh"}},[_c('thead',[_c('tr',_vm._l((_vm.headers),function(header){return _c('th',{key:header.text},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)]),_c('tbody',_vm._l((_vm.sales),function(item){return _c('tr',{key:item.reference},_vm._l((_vm.headers),function(i){return _c('td',{key:i.value},[_vm._v(" "+_vm._s(item[i.value])+" ")])}),0)}),0)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }