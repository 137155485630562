<template>
  <div>
    <v-row>
      <v-col>
        <v-card
          outlined
        >
          <div class="d-flex justify-space-between pt-4 pb-4">
            <div>
              <v-card-title>
                {{ cancellation[0][0].meses_referencia }}
              </v-card-title>

              <v-card-text class="text-no-wrap mt-4">
                <p class="text-xs mb-0">
                  Cancelamento - {{ cancellation[0][0].reference }}
                </p>
                <span class="text-xl font-weight-semibold text--primary me-1">{{ cancellation[0][0].cancelado }} / {{ cancellation[0][0].total }}</span>
              </v-card-text>
            </div>

            <vue-apex-charts
              id="chart-stats-total-sales"
              height="140"
              :options="chartOptions"
              :series="chartSeries"
            />
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined>
          <div class="d-flex justify-space-between pt-4 pb-4">
            <div>
              <v-card-title>
                {{ cancellation[0][1].meses_referencia }}
              </v-card-title>

              <v-card-text class="text-no-wrap mt-4">
                <p class="text-xs mb-0">
                  Cancelamento - {{ cancellation[0][1].reference }}
                </p>
                <span class="text-xl font-weight-semibold text--primary me-1">{{ cancellation[0][1].cancelado }} / {{ cancellation[0][1].total }}</span>
              </v-card-text>
            </div>

            <vue-apex-charts
              id="chart-stats-total-sales"
              height="140"
              :options="chartOptions2"
              :series="chartSeries2"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <div class="d-flex justify-space-between pt-3 pb-3">
            <div>
              <v-card-title>
                {{ cancellation[0][2].meses_referencia }}
              </v-card-title>

              <v-card-text class="text-no-wrap mt-4">
                <p class="text-xs mb-0">
                  Cancelamento - {{ cancellation[0][2].reference }}
                </p>
                <span class="text-xl font-weight-semibold text--primary me-1">{{ cancellation[0][2].cancelado }} / {{ cancellation[0][2].total }}</span>
              </v-card-text>
            </div>

            <vue-apex-charts
              id="chart-stats-total-sales"
              height="140"
              :options="chartOptions3"
              :series="chartSeries3"
            />
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined>
          <div class="d-flex justify-space-between pt-3 pb-3">
            <div>
              <v-card-title>
                {{ cancellation[0][3].meses_referencia }}
              </v-card-title>

              <v-card-text class="text-no-wrap mt-4">
                <p class="text-xs mb-0">
                  Cancelamento - {{ cancellation[0][3].reference }}
                </p>
                <span class="text-xl font-weight-semibold text--primary me-1">{{ cancellation[0][3].cancelado }} / {{ cancellation[0][3].total }}</span>
              </v-card-text>
            </div>

            <vue-apex-charts
              id="chart-stats-total-sales"
              height="140"
              :options="chartOptions4"
              :series="chartSeries4"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { getVuetify } from '@core/utils'

import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    cancellation: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartSeries: [Number(this.cancellation[0][0].indice), 100 - Number(this.cancellation[0][0].indice)],
      chartOptions: {
        chart: {
          offsetY: 1,
          type: 'donut',
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: true,
          },
        },
        stroke: {
          colors: ['#fff'],
          width: 1,
        },
        grid: {
          padding: {
            bottom: 5,
          },
        },
        colors: ['#ff4c51', '#d4d5d7'],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '20px',
                  offsetY: 15,
                },
                value: {
                  offsetY: -8,
                },
                total: {
                  show: true,
                  label: '',
                  formatter(value) {
                    return `${value.config.series[0]}%`
                  },
                },
              },
            },
          },
        },
      },

      // Gráfico 02 de retenção
      chartSeries2: [Number(this.cancellation[0][1].indice), 100 - Number(this.cancellation[0][1].indice)],
      chartOptions2: {
        chart: {
          offsetY: 1,
          type: 'donut',
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: true,
          },
        },
        stroke: {
          colors: ['#fff'],
          width: 1,
        },
        grid: {
          padding: {
            bottom: 5,
          },
        },
        colors: ['#ff4c51', '#d4d5d7'],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '20px',
                  offsetY: 15,
                },
                value: {
                  offsetY: -8,
                },
                total: {
                  show: true,
                  label: '',
                  formatter(value) {
                    return `${value.config.series[0]}%`
                  },
                },
              },
            },
          },
        },
      },

      // Gráfico 03 de retenção
      chartSeries3: [Number(this.cancellation[0][2].indice), 100 - Number(this.cancellation[0][2].indice)],
      chartOptions3: {
        chart: {
          offsetY: 1,
          type: 'donut',
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: true,
          },
        },
        stroke: {
          colors: ['#fff'],
          width: 1,
        },
        grid: {
          padding: {
            bottom: 5,
          },
        },
        colors: ['#ff4c51', '#d4d5d7'],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '20px',
                  offsetY: 15,
                },
                value: {
                  offsetY: -8,
                },
                total: {
                  show: true,
                  label: '',
                  formatter(value) {
                    return `${value.config.series[0]}%`
                  },
                },
              },
            },
          },
        },
      },

      // Gráfico 04 de retenção
      chartSeries4: [Number(this.cancellation[0][3].indice), 100 - Number(this.cancellation[0][3].indice)],
      chartOptions4: {
        chart: {
          offsetY: 1,
          type: 'donut',
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: true,
          },
        },
        stroke: {
          colors: ['#fff'],
          width: 1,
        },
        grid: {
          padding: {
            bottom: 5,
          },
        },
        colors: ['#ff4c51', '#d4d5d7'],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '20px',
                  offsetY: 15,
                },
                value: {
                  offsetY: -8,
                },
                total: {
                  show: true,
                  label: '',
                  formatter(value) {
                    return `${value.config.series[0]}%`
                  },
                },
              },
            },
          },
        },
      },
    }
  },
  computed: {
  },

  async created() {
    this.$vuetify = getVuetify()
    this.test = 58
  },
  methods: {
    calcRetentionPercent() {
    },
  },
}
</script>
<style lang="scss">
  #chart-stats-total-sales {
    max-width: 150px;
    height: auto;
    .apexcharts-pie {
      .apexcharts-pie-series .apexcharts-pie-area {
        stroke-width: 10;
      }
    }
    .apexcharts-canvas {
      .apexcharts-text {
        &.apexcharts-datalabel-value {
          font-weight: 600;
          font-size: 1.25rem;
        }
        &.apexcharts-datalabel-label {
          font-size: 0.75rem;
          margin-bottom: 2rem;
          transform: translateY(-7px);
        }
      }
    }
  }

  .v-application {
    &.theme--dark {
      #chart-stats-total-sales {
        path {
          stroke: #312d4b;
          filter: none;
        }
      }
    }
  }
  </style>
