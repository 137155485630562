import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"md":"12"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("Top 5 melhores vendedores 🔥😍")])]),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.vendors[0],"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:("v-avatar-light-bg " + (_vm.resolveUserProgressVariant(item.progress)) + "--text"),attrs:{"color":_vm.resolveUserProgressVariant(item.progress),"size":"30"}},[_c('span',{staticClass:"font-weight-medium",attrs:{"color":_vm.resolveUserProgressVariant(item.progress)}},[_vm._v(_vm._s(_vm.avatarText(item.name)))])]),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary  font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.name))])])],1)]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(Number(item.progress))+"%")]),_c(VProgressLinear,{staticClass:"project-progress mt-1",attrs:{"height":"6","rounded":"","color":_vm.resolveUserProgressVariant(item.progress),"value":Number(item.progress)}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }