<template>
  <v-card
    outlined
    class="statistics-horizontal"
  >
    <div
      class="d-flex align-center pt-2 pb-2 pl-2 pr-4"
    >
      <v-list-item-avatar
        :color="infos.backgroundIcon"
        rounded
        size="50"
        class="v-avatar-light-bg justify-center"
      >
        <v-icon
          size="30"
          :color="infos.color"
        >
          {{ infos.avatar }}
        </v-icon>
      </v-list-item-avatar>
      <div class="ms-3">
        <span class="text-xs">{{ infos.statTitle }}</span> {{ infos.reference ? '-' : '' }} <span class="text-xs">{{ infos.reference ? infos.reference : '' }}</span>
        <p class="text-no-wrap mb-0">
          <span class="text-xl font-weight-semibold text--primary">
            {{ infos.statistics }}
          </span>
          {{ infos.adimplencia ? '-' : '' }}
          <span
            :class="Number(infos.adimplencia) >= 70 ? 'repayment' : 'repaymentError'"
          >
            {{ infos.adimplencia }}{{ infos.adimplencia ? '%' : '' }}
          </span>
        </p>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        :to="infos.router"
        @click="infos.event"
      >
        <v-icon size="30">
          {{ icons.mdiDotsHorizontalCircle }}
        </v-icon>
      </v-btn>
    </div>
  </v-card>
</template>
<script>
import { mdiDotsHorizontalCircle, mdiDotsVertical } from '@mdi/js';

export default {
  props: {
    infos: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiDotsHorizontalCircle,
      },
    }
  },
}
</script>
<style>
.repayment{
  color: #56CA00;
}
.repaymentError{
  color: #FF4C51;
}
</style>
