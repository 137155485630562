<template>
  <div
    class="finance-summery-right-content-width mb-3"
    md="6"
  >
    <span>{{ current }} / {{ target }}</span>
    <div
      class="d-flex align-center"
      md="12"
    >
      <v-progress-linear
        :key="current"
        :color="calcProgress <= 25 ? 'error' :
          calcProgress > 25 && calcProgress <= 60 ? 'warning' :
          calcProgress > 60 && calcProgress <= 85 ? 'info' :
          'success'"
        :value="calcProgress"
        rounded
        height="10"
      ></v-progress-linear>
      <span class="text-base font-weight-semibold ms-4">{{ Math.round(calcProgress) }}%</span>
    </div>
    <v-dialog
      v-model="verifyTarget"
      width="600"
    >
      <congratulation-vendor
        :user="userData"
        :progress="calcProgress"
      ></congratulation-vendor>
    </v-dialog>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import CongratulationVendor from './CongratulationVendor.vue'

export default {
  components: {
    CongratulationVendor,
  },
  data() {
    return {
      current: 0,
      target: 1,
      userData: {
        name: '',
        target: '',
      },
    }
  },

  computed: {
    calcProgress() {
      const progress = (this.current / this.target) * 100

      return progress
    },

    verifyTarget() {
      const congrats = localStorage.getItem('congratulation')
      if (this.current >= this.target && congrats === 'true') {
        return true
      }

      return false
    },

    calcColor() {
      let colorDefault
      if (this.calcProgress <= 25) {
        colorDefault = 'error'
      }

      return colorDefault
    },
  },

  async created() {
    await this.getTarget()

    this.userData = {
      name: localStorage.getItem('username'),
      target: this.current,
    }
  },

  methods: {
    async getTarget() {
      await axiosIns.get('api/v1/integration/contract/dash_sales')
        .then(res => {
          this.current = res.data.data[0].sales
          this.target = res.data.data[0].goal
        })
    },
  },
}
</script>
