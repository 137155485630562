<template>
  <v-card>
    <v-card-title>
      Vendas
      <v-spacer></v-spacer>
      <v-alert
        text
        color="success"
      >
        Média: {{ average }}
      </v-alert>
    </v-card-title>
    <v-card-subtitle>Suas vendas nos últimos 6 meses 🚀</v-card-subtitle>
    <v-card-text>
      <vue-apex-charts
        type="donut"
        height="455"
        :options="donutChart.chartOptions"
        :series="donutChart.series"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  data() {
    return {
      chartColors: {},
      donutChart: {},
      average: '',
    }
  },

  async created() {
    // colors
    this.chartColors = {
      column: {
        series1: '#826af9',
        series2: '#d2b0ff',
        bg: '#f8d3ff',
      },
      success: {
        shade_100: '#7eefc7',
        shade_200: '#06774f',
      },
      donut: {
        series1: '#ffe700',
        series2: '#00d4bd',
        series3: '#826bf8',
        series4: '#2b9bf4',
        series5: '#FFA1A1',
      },
      area: {
        series3: '#e0cffe',
        series2: '#b992fe',
        series1: '#ab7efd',
      },
    }

    await this.getDataChart()
  },
  methods: {
    async getDataChart() {
      const dataMonth = []
      const month = []
      await axiosIns.get('api/v1/integration/contract/holding_block')
        .then(res => {
          res.data.data.map(item => {
            dataMonth.push(Number(item.sales))
            month.push(item.reference)

            const totalSum = dataMonth.reduce((sum, index) => sum + index)
            this.average = Math.round(totalSum / 6)

            this.donutChart = {
              series: dataMonth,
              chartOptions: {
                legend: {
                  show: true,
                  position: 'bottom',
                  fontSize: '20px',
                  fontFamily: '"Inter", sans-serif',
                },
                colors: [
                  this.chartColors.donut.series1,
                  this.chartColors.donut.series5,
                  this.chartColors.donut.series3,
                  this.chartColors.donut.series2,
                  this.chartColors.donut.series4,
                  this.chartColors.column.series2,
                ],
                dataLabels: {
                  enabled: true,
                  formatter(val) {
                  // eslint-disable-next-line radix
                    return `${parseInt(val)}%`
                  },
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '2rem',
                          fontFamily: '"Inter", sans-serif',
                        },
                        value: {
                          fontSize: '1rem',
                          fontFamily: '"Inter", sans-serif',
                          formatter(val) {
                          // eslint-disable-next-line radix
                            return `${parseInt(val)} cotas`
                          },
                        },
                        total: {
                          show: true,
                          fontSize: '1.5rem',
                          label: 'Total',
                          formatter() {
                            return `${totalSum} cotas`
                          },
                        },
                      },
                    },
                  },
                },
                labels: month,
                responsive: [
                  {
                    breakpoint: 992,
                    options: {
                      chart: {
                        height: 380,
                      },
                      legend: {
                        position: 'bottom',
                      },
                    },
                  },
                  {
                    breakpoint: 576,
                    options: {
                      chart: {
                        height: 320,
                      },
                      plotOptions: {
                        pie: {
                          donut: {
                            labels: {
                              show: true,
                              name: {
                                fontSize: '1.5rem',
                              },
                              value: {
                                fontSize: '3rem',
                              },
                              total: {
                                fontSize: '1.5rem',
                              },
                            },
                          },
                        },
                      },
                      legend: {
                        show: true,
                      },
                    },
                  },
                ],
              },
            }
          })
        })
    },

  },
}
</script>
