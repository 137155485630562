import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCol,{staticClass:"mt-5",attrs:{"cols":"12","sm":"12","order":"2","order-md":"1"}},[_c(VCard,{staticClass:"triangle-card"},[_c(VRow,[_c(VCol,{attrs:{"cols":"8"}},[_c(VCardTitle,{staticClass:"page-title text-no-wrap pt-2"},[_vm._v(" Parabéns "+_vm._s(_vm.user.name)+"! 🥳 ")]),_c(VCardSubtitle,{staticClass:"text-no-wrap"},[_vm._v(" Você já bateu a meta deste mês! ")]),_c(VCardText,{staticClass:"d-flex align-center pb-2"},[_c('div',[_c('p',{staticClass:"text-2xl font-weight-semibold primary--text mb-0"},[_vm._v(" "+_vm._s(_vm.user.target)+" cotas ")]),_c('p',[_vm._v(_vm._s(Math.round(_vm.progress))+"% da meta 🤟🏻")]),_c(VSwitch,{attrs:{"inset":"","color":"purple","label":'Não mostrar novamente'},model:{value:(_vm.switch1),callback:function ($$v) {_vm.switch1=$$v},expression:"switch1"}})],1)])],1),_c(VCol,{staticClass:"triangle-bg align-self-end",attrs:{"cols":"4"}},[_c(VImg,{staticClass:"triangle-bg d-flex align-self-end",attrs:{"width":"166px","height":"190px","src":require(("@/assets/images/misc/triangle-" + (_vm.$vuetify.theme.dark ? 'dark':'light') + ".png"))}},[_c(VImg,{staticClass:"gamification-trophy mt-16 ml-11",attrs:{"height":"108","max-width":"83","src":require("@/assets/images/misc/trophy.png")}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }