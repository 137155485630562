<template>
  <div
    class="finance-summery-right-content-width mb-3"
    md="6"
  >
    <span>{{ sales[sales.length - 1].sales }} / {{ Number(targetProgress[0].goal_value) }}</span>
    <div
      class="d-flex align-center"
      md="12"
    >
      <v-progress-linear
        :key="sales"
        :color="calcProgress <= 25 ? 'error' :
          calcProgress > 25 && calcProgress <= 60 ? 'warning' :
          calcProgress > 60 && calcProgress <= 85 ? 'info' :
          'success'"
        :value="calcProgress"
        rounded
        height="10"
        class="bar-progress-linear"
      ></v-progress-linear>
      <span class="text-base font-weight-semibold ms-4">{{ Math.round(calcProgress) }}%</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sales: {
      type: Array,
      required: true,
    },
    targetProgress: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      current: 1,
      target: 5,
    }
  },

  computed: {
    calcProgress() {
      const progress = (Number(this.sales[this.sales.length - 1].sales) / Number(this.targetProgress[0].goal_value)) * 100

      return progress
    },

    calcColor() {
      let colorDefault
      if (this.calcProgress <= 25) {
        colorDefault = 'error'
      }

      return colorDefault
    },
  },

  methods: {
  },
}
</script>
<style lang="scss" scoped>
  .bar-progress-linear{
    transition: all 0.2s ease;
  }
</style>
